export enum endpoints {
    LOGIN = "/v2/auth/public/login",
    REFRESH_TOKEN = "/v2/auth/public/token/refresh",
    USER_PROFILE = "/v2/auth/users/profile",
    USER_PROFILE_LANGUAGE_CHANGE = "/v2/auth/users/language/update",
    SEND_OTP = "/v2/auth/public/otp/send",
    VERIFY_OTP = "/v2/auth/public/otp/verify",
    DOWNLOAD_FILE = '/:fileName?key=:key',
    UPLOAD = '/v2/files/upload',
    UPLOAD_PRODUCT = '/v2/files/product/upload/:internationalCode',
    UPLOAD_CATEGORY = '/v2/files/category/upload/:categoryId',
    UPLOAD_BANNER = '/v2/files/banners/upload/:bannerId',



    GET_CONSTANTS = "/v2/common/constants",

    // notifications
    NOTIFICATIONS_WEB_SUBSCRIBE = '/v2/notification/users/web/subscribe',
    NOTIFICATIONS_MESSAGES_COUNT = '/v2/notification/users/messages/count',
    NOTIFICATIONS_MESSAGES_ALL = '/v2/notification/users/messages/all',
    NOTIFICATIONS_MESSAGES_MARK_AS_READ = '/v2/notification/users/messages/read',

    // dashboard
    DASHBOARD_ORDERS_COUNT = '/v2/dashboard/orders/count',
    DASHBOARD_ORDERS_CHARTS_COUNTS = '/v2/dashboard/orders/status/count',
    DASHBOARD_SALES_TOTAL = '/v2/dashboard/sales/total',
    DASHBOARD_ACCOUNTS_BALANCE = '/v2/dashboard/accounts/balance/total',
    DASHBOARD_PARTNERS_COUNT = '/v2/dashboard/partners/count',
    DASHBOARD_PARTNERS_CHART = '/v2/dashboard/orders/stores/total',
    DASHBOARD_ORDERS_TOTALS_CHART = '/v2/dashboard/orders/total',
    DASHBOARD_ORDERS_PER_ZONE_CHART = '/v2/dashboard/orders/zones/total',
    DASHBOARD_ORDERS_PER_STATE_CHART = '/v2/dashboard/orders/states/total',


    // users
    USERS_SEARCH = '/v2/auth/users/search',
    USERS_CREATE_OR_SAVE = '/v2/auth/users',
    USERS_DELETE = '/v2/auth/users/delete',
    USERS_SUSPEND = '/v2/auth/users/suspend',
    USERS_DELETE_ALL = '/v2/auth/users/delete/all',
    USERS_GET_DETAILS = '/v2/auth/users/get',
    USERS_CHANGE_PASSWORD = '/v2/auth/users/password/reset',

    // partners
    PARTNERS_SEARCH = '/v2/partners/search',
    PARTNERS_CREATE_OR_SAVE = '/v2/partners/save',
    PARTNERS_DELETE = '/v2/partners/delete',
    PARTNERS_DELETE_ALL = '/v2/partners/delete/all',
    PARTNERS_GET_DETAILS = '/v2/partners/get',
    PARTNERS_GET_DETAILS_BY_CONNECTION = '/v2/partners/connection/get',
    PARTNERS_VERIFY = '/v2/partners/verify',
    PARTNERS_CREATE_WALLET = '/v2/partners/create/wallet',
    PARTNERS_GET_MAPPING = '/v2/partners/mapping/get',
    PARTNERS_SAVE_MAPPING = '/v2/partners/mapping/save',
    PARTNERS_DELETE_MAPPING = '/v2/partners/mapping/delete',

    // connections
    PARTNER_CONNECTIONS_SEARCH = '/v2/connections/search',
    PARTNER_CONNECTIONS_GET = '/v2/connections/get',
    PARTNER_CONNECTIONS_SET_CLASSIFICATION = '/v2/connections/classification/all',

    PARTNER_CONNECTIONS_SET_ITEM_CLASSIFICATION = '/v2/connections/classification',
    PARTNER_CONNECTIONS_ADD_CLASSIFICATION = '/v2/connections/add',
    //payment partners
    PAYMENT_PARTNERS_SEARCH = '/v2/payment-partner/search',
    PAYMENT_PARTNERS_CREATE_OR_SAVE = '/v2/payment-partner/',
    PAYMENT_PARTNERS_GET_DETAILS = '/v2/payment-partner/get',
    PAYMENT_PARTNERS_DELETE = '/v2/payment-partner/delete',
    PAYMENT_PARTNERS_DELETE_ALL = '/v2/payment-partner/delete/all',
    PAYMENT_PARTNERS_ACTIVATE_ALL = '/v2/payment-partner/activate/all',
    PAYMENT_PARTNERS_DEACTIVATE_ALL = '/v2/payment-partner/deactivate/all',


    //payment transactions
    PAYMENT_TRANSACTIONS_SEARCH = '/v2/payment/transaction/search',
    PAYMENT_TRANSACTIONS_GET_DETAILS = '/v2/payment/transaction/get',
    //payment orders
    PAYMENT_ORDERS_SEARCH = '/v2/paymentOrders/',
    PAYMENT_ORDERS_RELEASE_AMOUNT = '/v2/payment/release-amount',
    PAYMENT_ORDERS_GET_DETAILS = '/v2/paymentOrders/details',
    //broken orders
    BROKEN_ORDERS_SEARCH = '/v2/orders/brokenSalesOrder',
    BROKEN_ORDERS_RESOLVE = '/v2/payment/resolveBrokenOrder',
    //wrong orders
    WRONG_ORDERS_SEARCH ='/v2/wrongOrder/',
    WRONG_ORDERS_GET_DETAILS='/v2/wrongOrder/details',

    //delivery partners
    DELIVERY_PARTNERS_SEARCH = '/v2/delivery-partner/search',
    DELIVERY_PARTNERS_CREATE_OR_SAVE = '/v2/delivery-partner/',
    DELIVERY_PARTNERS_GET_DETAILS = '/v2/delivery-partner/get',
    DELIVERY_PARTNERS_DELETE = '/v2/delivery-partner/delete',
    DELIVERY_PARTNERS_DELETE_COST = '/v2/delivery-partner/delivery-cost/delete',
    DELIVERY_PARTNERS_DELETE_ALL = '/v2/delivery-partner/delete/all',
    DELIVERY_PARTNERS_ACTIVATE_ALL = '/v2/delivery-partner/activate/all',
    DELIVERY_PARTNERS_DEACTIVATE_ALL = '/v2/delivery-partner/deactivate/all',

    // products
    SAERCH_PRODUCTS = '/v2/products/search',
    SAERCH_PRODUCTS_SIMILAR = '/v2/products/search/similar',
    CREATE_OR_SAVE_PRODUCTS = '/v2/products',
    DELETE_PRODUCTS = '/v2/products/delete',
    DELETE_PRODUCTS_ALL = '/v2/products/delete/all',
    ADD_PRODUCT_IMAGE = '/v2/products/image',
    GET_PRODUCT = '/v2/products/get',
    VERIFY_PRODUCT = '/v2/products/verify',
    LINK_PRODUCT = '/v2/products/link',
    MATCH_PRODUCT = '/v2/products/match',

    // store_products
    STORE_PRODUCT_STAGE_IMPORT = '/v2/stores/products/upload2',
    STORE_PRODUCTS_STAGE_SAERCH = '/v2/stores/products/stg/search',
    STORE_PRODUCT_STAGE_APPROVE = '/v2/stores/products/stg/approve',
    STORE_PRODUCT_STAGE_REJECT = '/v2/stores/products/stg/reject',
    STORE_PRODUCT_STAGE_DOWNLOAD_TEMPLATE = '/v2/stores/products/csv-template',
    STORE_PRODUCT_STAGE_DOWNLOAD_CATALOG = '/v2/stores/products/generateStoreProductExcel',


    STORE_PRODUCTS_SAERCH = '/v2/stores/products/search',
    STORE_PRODUCTS_SAERCH_SIMILAR = '/v2/stores/products/search/similar',
    STORE_PRODUCTS_SAERCH_SIMILAR_PRICE = '/v2/stores/products/search/similar/price/customer',
    STORE_PRODUCTS_GET_PRICE = '/v2/stores/products/get/price/customer',
    STORE_PRODUCTS_CREATE_OR_SAVE = '/v2/stores/products',
    STORE_PRODUCTS_GET = '/v2/stores/products/get',
    STORE_PRODUCTS_DELETE = '/v2/stores/products/delete',
    STORE_PRODUCTS_DELETE_ALL = '/v2/stores/products/delete/all',
    STORE_PRODUCTS_ACTIVATE_ALL = '/v2/stores/products/activate/all',
    STORE_PRODUCTS_DEACTIVATE_ALL = '/v2/stores/products/deactivate/all',
    STORE_PRODUCTS_UPDATE_QUANTITY = '/v2/stores/products/quantity/update',
    STORE_PRODUCTS_STOCK_SAERCH = '/v2/stores/products/stock/search',
    STORE_PRODUCTS_UPDATE_PRODUCT_QUANTITY = '/v2/stores/products/quantity/order/update',
    //packages
    PACKAGES_SAERCH = '/v2/sales-offer/offers-price-list/paged',
    SALES_OFFER_ALL_SEARCH = '/v2/sales-offer/offers/paged',
    CREATE_SALES_OFFER = '/v2/sales-offer/offers/save',
    PACKAGES_DETAILS = '/v2/sales-offer/offers/items',
    CART_OFFER_ITEMS_ADD = '/v2/shopping/carts/items/offer/add',
    SALES_OFFER_ACTIVATE_ALL = '/v2/sales-offer/offers/activate/all',
    SALES_OFFER_DEACTIVATE_ALL = '/v2/sales-offer/offers/deactivate/all',
    SALES_OFFER_DELETE_ALL = '/v2/sales-offer/offers/delete/all',
    SALES_OFFER_GET = '/v2/sales-offer/offers/get',

    //utils
    UTILS_CALC_SELLING_PRICE = '/v2/Utils/sellingPrice/calc',
    UTILS_CALC_SUB_TOTAL = '/v2/Utils/subTotal/calc',
    UTILS_CALC_SUMMARY = '/v2/Utils/summary/calc',
    // sales-carts
    CARTS_SEARCH = '/v2/shopping/carts/search',
    CARTS_SEARCH1 = '/v2/shopping/carts/search1',
    CART_GET = '/v2/shopping/carts/get',
    CART_GET_GET_ITEMS = '/v2/shopping/carts/getCartItems',
    CART_GET_BY_ID = '/v2/shopping/carts/getById',
    CART_ITEMS_ADD = '/v2/shopping/carts/items/add',
    CART_ITEM_CALC = '/v2/shopping/carts/item/calc',
    CART_ITEMS_UPDATE = '/v2/shopping/carts/items/update',
    CART_ITEMS_DELETE = '/v2/shopping/carts/items/delete',
    CART_CART_DELETE = '/v2/shopping/carts/delete',
    CART_CART_CHECKOUT_ALL = '/v2/shopping/carts/checkout',
    CART_PAYMENT_MODE = '/v2/shopping/carts/payment/modes',

    // sales-orders
    ORDERS_SEARCH = '/v2/orders/search',
    ORDER_GET = '/v2/orders/get',
    ORDER_CANCEL = '/v2/orders/cancel',
    ORDER_SHIP = '/v2/orders/ship',
    ORDER_READY = '/v2/orders/ready-for-delivery',
    ORDER_CONFIRM_AND_READY = '/v2/orders/process-and-ready',
    ORDER_CONFIRM_READY_SHIP = '/v2/orders/process-ready-ship',
    ORDER_READY_SHIP = '/v2/orders/ready-ship',
    ORDER_SHIP_ALL = '/v2/orders/ship/all',
    ORDER_RETURN_ALL = '/v2/orders/return/return/all',
    ORDER_CONFIRM = '/v2/orders/process',
    ORDER_CARRIER_CHANGE = '/v2/orders/update',
    ORDER_DELIVER = '/v2/orders/deliver',
    ORDER_CONFIRM_READY_SHIP_DELIVER = '/v2/orders/process-ready-ship-deliver',
    ORDER_READY_SHIP_DELIVER = '/v2/orders/ready-ship-deliver',
    ORDER_SHIP_DELIVER = '/v2/orders/ship-deliver',
    ORDER_DELIVER_ALL = '/v2/orders/deliver/all',
    ORDER_RETURN = 'v2/orders/returnOrder',
    ORDER_RETURN_CANCEL = '/v2/orders/return/cancel',
    ORDER_ITEM_UPDATE = '/v2/orders/items/update',
    ORDER_GET_DELIVERY_PARTNER = '/v2/delivery-partner/salesOrder',
    ORDER_ADD_NOTE = '/v2/orders/add-note',
    ORDER_GET_ITEMS= '/v2/orders/getOrderItems',

    ORDER_UPLOAD_DOCUMENT = '/v2/orders/upload-documents',

    // sales-orders | stores | placed-processing
    STORES_ORDERS_SEARCH = '/v2/orders/stores/search',
    STORES_ORDERS_GET = '/v2/orders/stores/get',

    // sales-orders | customers | shipped
    CUSTOMERS_ORDERS_SEARCH = '/v2/orders/customers/search',
    CUSTOMERS_ORDERS_GET = '/v2/orders/customers/get',

    // sales-orders | returned
    RETURNED_ORDERS_SEARCH = '/v2/orders/return/search',
    RETURNED_ORDERS_GET = '/v2/orders/return/get',

    // sales-orders | returned | shipped
    RETURNED_SHIPPED_ORDERS_SEARCH = '/v2/orders/return/stores/search',
    RETURNED_SHIPPED_ORDERS_GET = '/v2/orders/return/stores/get',

    //
    CUSTOMER_RETURNABLE_ITEMS_SEARCH = '/v2/orders/return/customer/items',
    CUSTOMER_RETURN_ORDER_CREATE = '/v2/orders/return/create',

    //
    FINANCE_DOCUMENT_CREATE = '/v2/accounts/document/create',
    FINANCE_DOCUMENT_GET = '/v2/accounts/document/get',
    FINANCE_SEARCH_ACCOUNTS = '/v2/accounts/balance',
    FINANCE_PRODUCTS_BALANCE = '/v2/accounts/balance/get',
    FINANCE_PAYMENT_METHODS = '/v2/common/outbound/payment/methods/all',
    FINANCE_DOCUMENT_CREATE_TRANSACTIONAL = '/v2/accounts/upload-document-files',
    FINANCE_DOCUMENT_DELETE_TRANSACTIONAL = '/v2/accounts/delete-document-file',

    //
    REPORTS_STATEMENTS_FETCH = '/v2/reports/statement',
    REPORTS_DOCUMENTS_FETCH = '/v2/reports/documents',
    REPORTS_INVOICES_FETCH = '/v2/reports/invoices',
    REPORTS_TRAIL_BALANCE_FETCH = '/v2/reports/accounts',
    REPORTS_SALES_ORDERS_FETCH = '/v2/reports/sales/orders',

    // address
    STATES_SEARCH = '/v2/common/states/all',

    // zones
    ZONES_SEARCH = '/v2/zones/search',
    ZONES_CREATE_OR_SAVE = '/v2/zones',
    ZONES_GET_DETAILS = '/v2/zones/get',
    ZONES_DELETE = '/v2/zones/delete',
    ZONES_DELETE_ALL = '/v2/zones/delete/all',

    // zones-store
    STORE_ZONES_SEARCH = '/v2/stores/zones/search',
    STORE_ZONES_CREATE = '/v2/stores/zones',
    STORE_ZONES_DELETE = '/v2/stores/zones/delete',
    STORE_ZONES_DELETE_ALL = '/v2/stores/zones/delete/all',

    NOTIFICATIONS_CAMPAIGN = '/v2/notification/campaign',

    PRICE_LIST_GET_ALL = '/v2/price/list/all',
    PRICE_SCHEME_GET_BY_SOTREID_AND_PRICE_LIST_ID = '/v2/price/scheme/details',
    PRICE_SCHEME_CREATE_OR_UPDATE = '/v2/price/scheme',
    PRICE_SCHEME_DELETE_BONUST_BY_ID = '/v2/price/scheme/delete/bonus',
    PRICE_SCHEME_DELETE_PRICE_SCHEME = '/v2/price/scheme/delete',
    COMMISSION_GET_BY_PRICE_LIST_ID_AND_PARTNER_ID = '/v2/commission/details',
    COMMISSION_CREATE_OR_UPDATE = '/v2/commission',
    COMMISSION_CHECK_PRICE = '/v2/commission/check/price',
    COMMISSION_CHECK_ORDER_MIN = '/v2/commission/check/order-min',

    // topup | partners
    TOPUP_PARTNERS_SEARCH = '/v2/topup/partners/search',
    TOPUP_PARTNERS_CREATE_OR_SAVE = '/v2/topup/partners',
    TOPUP_PARTNERS_DELETE = '/v2/topup/partners/delete',
    TOPUP_PARTNERS_CLEANUP = '/v2/topup/partners/cleanup',
    TOPUP_PARTNERS_DELETE_ALL = '/v2/topup/partners/delete/all',
    TOPUP_PARTNERS_GET_DETAILS = '/v2/topup/partners/get',

    // topup | transactions
    TOPUP_TRANSACTIONS_SEARCH = '/v2/topup/transactions/search',
    TOPUP_TRANSACTIONS_GET_DETAILS = '/v2/topup/transactions/get',
    WALLET_TRANSACTION_SEARCH = '/v2/wallet/transaction/search',
    WALLET_TRANSACTION_DETAILS = '/v2/wallet/transactions/get',


    //settings
    SETTINGS_CATEGORY = '/v2/settings/category/all',
    SETTINGS_CATEGORY_ADD_UPDATE = '/v2/settings/category/add',
    SETTINGS_CATEGORY_DETAILS = '/v2/settings/category/get',
    SETTINGS_CATEGORY_DELETE = '/v2/settings/category/delete',

    SETTINGS_BANNER = '/v2/banners/all',
    SETTINGS_BANNER_ADD_UPDATE = '/v2/banners',
    SETTINGS_BANNER_DETAILS = '/v2/banners/get',
    SETTINGS_BANNER_DELETE = '/v2/banners/delete',

    IMPORT_TASK_SEARCH = '/v2/tasks/imports/paged',
    STORE_RAW_DETAILS = '/v2/tasks/items/details',
    STORE_RAW_REFRESH_MATCHECS = '/v2/tasks/items/refresh',
    STORE_RAW_UPDATE_MATCH = '/v2/tasks/items/update-match',
    STORE_RAW_SEARCH = '/v2/tasks/items/search',
    IMPORT_TAKS_RESTART = '/v2/tasks/imports/restart',
    IMPORT_TAKS = '/v2/tasks/imports',
    IMPORT_TAKS_DOWNLOAD = '/v2/tasks/imports/export/xlsx',
    STORE_IMPORT_SETTINGS = '/v2/tasks/settings',
    IMPORT_STORE_PRODUCT_TASK_SEARCH = '/v2/stores/products/imports/paged',

}


export function getBaseUrl(): string {
    return process.env.REACT_APP_BACKEND_URL ?? '';
}
